<template>
  <div>
    <!-- type:{{type}} -->
    <el-row class="mt-10 mb-10" v-if="ifBatch">
      <el-button @click="mapTaskAndUser('已启用')" size="small">批量开放</el-button>
      <el-button @click="mapTaskAndUser('已禁用')" size="small">批量关闭</el-button>
    </el-row>

    <el-table
      ref="type"
      :data="classStudentList"
      tooltip-effect="dark"
      border
      style="width: 100%;margin-top: 15px;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        v-if="ifBatch"
        align="center"
        key="selection"
        width="70">
      </el-table-column>
      <el-table-column
        prop="studentNo"
        label="学号"
        key="loginName"
        align="center">
      </el-table-column>
      <el-table-column
        label="姓名"
        key="name"
        align="center">
        <template slot-scope="scope">
          <span >{{scope.row.studentName}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!ifBatch"
        label="操作"
        align="center"
        key="operation"
        width="120">
        <template slot-scope="scope">
          <el-switch
            :active-value="true"
            :inactive-value="false"
            @change="switchCheckItem(scope.row)"
            class="stu-switch"
            v-model= "scope.row.isTaskOpen"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { switchCheckItem, mapTaskAndUser } from '@/api/senate/class'

export default {
  props: {
    classStudentList: {
      default: () => {
        return []
      },
      type: Array
    },
    ifBatch: {
      type: Boolean
    },
    task_id: {
      default: '',
      type: String
    },
    taskIdArray: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      multipleSelection: []
    }
  },
  watch: {
    ifBatch () {
      if (this.ifBatch) {
        console.log('666666')
        if (this.$refs.multipleTable) {
          this.$refs.multipleTable.$forceUpdate()
        }
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
      // console.log('handleSelectionChange', val)
    },

    async switchCheckItem(row) {
      await switchCheckItem({
        status: row.isTaskOpen ? '已启用' : '已禁用',
        taskId: this.task_id,
        userIds: [row.userId]
      })
      window.$msg('操作成功')
    },

    async mapTaskAndUser(state) {
      if (this.multipleSelection.length === 0) {
        window.$msg('请选择要开放的学生', 2)
        return false
      }
      let userChooseArray = []
      this.multipleSelection.forEach(ele => {
        userChooseArray.push(ele.userId)
      })
      const res = await mapTaskAndUser({
        userIds: userChooseArray,
        taskIds: this.taskIdArray,
        status: state
      })
      if (res.state === 'success') {
        if (state === '已启用') {
          window.$msg('开放学员成功')
        } else if (state === '已禁用') {
          window.$msg('关闭学员成功')
        }
        // this.$emit('update')
      }
    },
  }
}
</script>

<style>

</style>
