<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="5vh"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>开放学员</span>
    </div>
    <!-- task_id：{{task_id}} -->
    <!-- <hr> -->
    <!-- taskIdArray：{{taskIdArray}} -->
    <!-- classStudentList: {{classStudentList}} -->
    <open-pane
      :ifBatch="ifBatch"
      :task_id="task_id"
      :taskIdArray="taskIdArray"
      :classStudentList="classStudentList"
      @update="update"
    ></open-pane>

    <el-row slot="footer">
      <!-- <el-button size="small" @click="save" type="primary">保 存</el-button>
      <el-button size="small" type="info" @click="close">取 消</el-button> -->
    </el-row>
  </el-dialog>
</template>

<script>
import { getCourseStudentListWithTaskStatus} from '@/api/senate/class'
import openPane from './open-pane'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    ifBatch: {
      default: false,
      type: Boolean
    },
    task_id: {
      default: '',
      type: String
    },
    taskIdArray: {
      default: () => {
        return []
      },
      type: Array
    }
  },

  components: { openPane },
  data() {
    return{
      classStudentList: [],
    }
  },
  methods: {
    openDialog() {
      this.getCourseStudentListWithTaskStatus()
    },

    update() {
      this.getCourseStudentListWithTaskStatus()
    },

    async getCourseStudentListWithTaskStatus() {
      const res = await getCourseStudentListWithTaskStatus({
        courseId: this.$route.query.courseId,
        taskId:this.task_id,
      })
      this.classStudentList = res.body
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../style/dialog.scss';
</style>
