<template>
  <div>
    <el-row class="box-line my-lesson">
      课程目录
      <!-- taskIdArray：{{taskIdArray}} -->
      <el-row class="lesson-num">
            <span>
              总课次：
              <font>{{classNum}}</font>
            </span>
        <span>
              总任务数：
              <font>{{taskNum}}</font>
            </span>
      </el-row>
      <el-collapse :key="topindex" v-for="(item, topindex) in taskData" accordion v-model="activeNames" @change="handleChange">
        <el-collapse-item :name="topindex + ''">
          <template slot="title">
            第{{((pagination.currentPage -1 ) * 10) + Number(topindex + 1)}}课：{{item.title}}
<!--            <el-button plain size="small" style="margin-left:20px;" type="primary" @click.stop="missedLessonManage(taskData[topindex])" >查询课件</el-button>-->
          </template>
          <el-row :reverse="reverse" style="padding-left: 20px;">
            <el-table
              tooltip-effect="dark"
              :data="taskList"
              fit
              highlight-current-row
              ref="taskList"
              @selection-change="handleTaskSelection"
            >
              <el-table-column
                align="center"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column label="序号" width="120" align="center">
                <template slot-scope="scope">
                  {{((pagination.currentPage -1 ) * 10) + Number(scope.$index + 1)}}
                </template>
              </el-table-column>
              <el-table-column label="任务标题" width="200" align="center">
                <template slot-scope="scope">
                  <span class="title-link" @click="previewTask(scope.row)">{{scope.row.title}}</span>
                </template>
              </el-table-column>
              <el-table-column label="类型" width="120" align="center">
                <template slot-scope="scope">
                  <span class="title-link">{{scope.row.type}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作学员" width="120" align="center">
                <template slot-scope="scope">
                  <el-button
                    plain
                    size="small"
                    slot="reference"
                    style="margin-left: 10px;margin-top: 5px;"
                    type="primary"
                    @click="openStudent(scope.row)"
                  >开放学员</el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="300" align="center">
                <template slot-scope="scope">
                  <el-button
                    plain
                    v-if="scope.row.type ==='python'"
                    size="small"
                    slot="reference"
                    type="primary"
                    @click.stop="submitListManage(scope.row)"
                  >提交记录</el-button>
                  <el-button
                    plain
                    class="previewquestion"
                    v-if="scope.row.type === '上传'"
                    size="small"
                    slot="reference"
                    @click.stop="priviewFile(scope.row)"
                    type="primary"
                  >查看作品</el-button>
                  <el-button
                    plain
                    class="previewquestion"
                    v-if="scope.row.type === 'scratch'"
                    size="small"
                    slot="reference"
                    @click.stop="priviewScratch(scope.row)"
                    type="primary"
                  >提交记录</el-button>
                  <el-button
                    plain
                    v-if="scope.row.type === 'goc'"
                    size="small"
                    slot="reference"
                    @click.stop="priviewGoC(scope.row)"
                    type="primary"
                  >查看提交</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="info" size="medium" style="margin-top: 20px;" @click="openBatchStudent()">批量开放</el-button>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
      <!-- 课件补课视频 dialog -->
<!--      <missed-lesson :applyCourseData="missLessonCourseData" :show="missLessonCourseFlag" @close="missLessonCourseFlag=false"></missed-lesson>-->
      <!-- 审批试卷 -->

      <preview-file
        :show="priviewFileDialog"
        :taskID="filetaskID"
        @close="priviewFileDialog=false">
      </preview-file>
      <preview-scratch
        :show="priviewScratchDialog"
        :taskID="scratchTaskID"
        @close="priviewScratchDialog=false"
      ></preview-scratch>

      <set-history-time
        :taskId="taskID"
        :lessonId="lessonId"
        :show="sethistoryDialog"
        @close="sethistoryDialog = false"
      ></set-history-time>

      <open-student
        :show="studentDialog"
        @close="studentDialog=false;ifBatch=false;task_id=''"
        :ifBatch="ifBatch"
        :task_id="task_id"
        :taskIdArray="taskIdArray"
      ></open-student>
    </el-row>
  </div>
</template>

<script>
import { mapTaskAndUser, getInfo, listOnly, switchCheckItem, switchCheckAll, getTaskCount } from '@/api/senate/class'
import PreviewFile from "@/components/senate/preview-file";
import PreviewScratch from "@/components/senate/preview-scratch";
import {getVideoUrl } from '@/api/video/videomanage';
import setHistoryTime from '@/components/senate/set-historytime'
import openStudent from './components/open-student'

export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      dataList: [],
      classNum: 0,
      taskNum: 0,
      taskData: [],
      switchAll: false,
      missLessonCourseFlag: false,
      missLessonCourseData: {},
      priviewFileDialog:false,
      priviewScratchDialog:false,
      markingDialog: false,
      taskID: '0',
      filetaskID:"0",
      scratchTaskID:"",
      lessonId: '',
      questionArray: [],
      activeNames: '',
      reverse: false,
      taskList: [],
      lessonList: [],
      taskIdArray: [],
      taskUserList: [],
      userChooseArray: [],
      currentConunitId: 0,
      sethistoryDialog:false,
      studentDialog: false,
      ifBatch: false,
      task_id: ''
    }
  },
  components: {PreviewFile,PreviewScratch,setHistoryTime,openStudent },
  filters: {
    formatTime (value) {
      return value.substring(0, value.length - 2)
    }
  },
  created () {
    // this.getCourseUnitList();
    this.listOnly()
    if (this.$route.query.courseId) {
      this.getTaskCount()
    }
  },
  methods: {
    priviewScratch(row) {
      this.scratchTaskID = row.id
      this.priviewScratchDialog = true
    },
    priviewFile(row) {
      this.filetaskID = row.id
      this.priviewFileDialog = true
    },
    getTaskCount () {
      getTaskCount({
        courseId: this.$route.query.courseId
      }).then(res => {
        if (res.state === 'success') {
          this.taskNum = res.body
        }
      })
    },
    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listOnly()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listOnly()
    },
    showPopover (row) {
      let newSomeArr = row.some((item, i) => {
        return item.status === '已禁用'
      })
      this.switchAll = !newSomeArr
    },
    handleTaskSelection (sels) {
      // this.taskIdArray = sels
      // if (sels.length > 1) {
      //   this.$refs.taskList[0].clearSelection()
      //   this.$refs.taskList[0].toggleRowSelection(sels.pop())
      // }
      this.taskIdArray = []
      for (let i = 0; i < sels.length; i++) {
        this.taskIdArray.push(sels[i].id)
      }
    },
    handleSelection (sels) {
      this.userChooseArray = []
      for (let i = 0; i < sels.length; i++) {
        this.userChooseArray.push(sels[i].userId)
      }
    },
    handleChange (val) {
      if (val === '') {
        return false
      }
      console.log(val)
      console.log(JSON.stringify(this.taskData))
      this.currentConunitId = this.taskData[val].id
      this.courseUnitList(this.taskData[val].id)
    },
    submitListManage (row) {
      this.$router.push({
        path: '/senate/submit_python',
        query: {
          taskId: row.id,
        }
      })
    },

    priviewGoC (row) {
      this.$router.push({
        path: '/senate/submit_list',
        query: {
          taskId: row.id,
          type: 'goc'
        }
      })
    },
    missedLessonManage (row) {
      this.missLessonCourseData = {}
      this.missLessonCourseData = row
      this.missLessonCourseData.enterType = 'teacher'
      this.missLessonCourseFlag = true
    },

    openStudent(row) {
      this.task_id = row.id
      this.studentDialog = true
    },

    openBatchStudent() {
      if (this.taskIdArray.length === 0) {
        window.$msg('请先选择要开放的任务', 2)
        return false
      }
      // this.task_id = this.taskIdArray[0].id
      this.ifBatch = true
      this.studentDialog = true
    },

    async listOnly () {
      const res = await listOnly({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: '已启用',
        courseId: this.$route.query.courseId,
      })
      if (res.state === 'success') {
        this.taskData = []
        this.classNum = 0
        this.pagination.total = res.body.total
        this.taskData = res.body.list
        this.classNum = res.body.total
      }
    },
    async courseUnitList (courseId) {
      const res = await getInfo({
        id: courseId,
        classId: this.$route.query.classID,
        onlyEnable: true
      })
      if (res.state === 'success') {
        this.taskList = []
        this.lessonList = []

        this.taskList = res.body.taskList
        this.taskUserList = this.taskList[0].taskUserList
        this.lessonList = res.body.lessonList
      }
    },
    async mapTaskAndUser (state) {
      if (this.taskIdArray.length === 0) {
        window.$msg('请先选择要开放的任务', 2)
        return false
      } else if (this.userChooseArray.length === 0) {
        window.$msg('请先选择要开放的学生', 2)
        return false
      }
      const res = await mapTaskAndUser({
        userIds: this.userChooseArray,
        taskIds: this.taskIdArray,
        classId: this.$route.query.classID,
        status: state
      })
      if (res.state === 'success') {
        if (state === '已启用') {
          window.$msg('开放学员成功')
        } else if (state === '已禁用') {
          window.$msg('关闭学员成功')
        }
        this.courseUnitList(this.currentConunitId)
      }
    },

    async switchCheckAll (row, status, parentIndex) {
      if (row.taskUserList.length === 0) {
        return false
      }
      var userIdArray = []
      for (let i = 0; i < row.taskUserList.length; i++) {
        userIdArray.push(row.taskUserList[i].userId)
      }
      if (!status) {
        status = '已禁用'
      } else {
        status = '已启用'
      }
      const res = await switchCheckAll({
        taskId: row.taskUserList[0].taskId,
        userIds: userIdArray,
        status: status
      })
      if (res.state === 'success') {
        if (status === '已禁用') {
          for (
            let i = 0; i < this.taskList[parentIndex].taskUserList.length; i++) {
            this.taskList[parentIndex].taskUserList[i].status = '已禁用'
          }
        } else {
          for (let i = 0; i < this.taskList[parentIndex].taskUserList.length; i++) {
            this.taskList[parentIndex].taskUserList[i].status = '已启用'
          }
        }
      }
    },

    async switchCheckItem (item, parentIndex, childindex) {
      var status = 0
      var userIdArray = []
      userIdArray.push(item.userId)
      if (item.status === '已启用') {
        status = '已禁用'
      } else {
        status = '已启用'
      }
      const res = await switchCheckItem({
        taskId: item.taskId,
        userIds: userIdArray,
        status: status
      })
      if (res.state === 'success') {
        if (item.status === '已启用') {
          this.taskList[parentIndex].taskUserList[childindex].status = '已禁用'
        } else {
          this.taskList[parentIndex].taskUserList[childindex].status = '已启用'
        }
      }
      let newSomeArr = this.taskList[parentIndex].taskUserList.some((item, i) => {
        return item.status === '已禁用'
      })
      this.switchAll = !newSomeArr
    },

    async getVideoUrl (fileId) {
      const res = await getVideoUrl({
        fileId:fileId
      })
      if(res.code === 1) {
        $msg(res.msg,2)
      }else if(res.code === 0 ) {
        let routeData = this.$router.resolve({
          path: '/research/preview-video',
          query: {
            pathUrl: res.data
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    // 预览任务
    previewTask (activity) {
      if(activity.type === "视频") {
        this.getVideoUrl(activity.sourceId)
      }else {
        let routeData = null
        if(activity.type === "pdf") {
          routeData = this.$router.resolve({
            path: '/research/pdfpreview',
            query: {
              taskID: activity.id,
            },
          })
        }else {
          routeData = this.$router.resolve({
            path: '/research/preview-task',
            query: {
              classID: this.$route.query.classID,
              taskID: activity.id,
              tab: this.$route.query.tab
            },
            params: { id: this.id }
          })
        }
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stuList {
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-top: 10px;
  overflow-y: auto;
  li {
    margin-top: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .stu-tit {
    display: inline-block;
    padding-left: 10px;
    justify-content: flex-start;
    cursor: pointer;
  }

  .stu-switch {
    float: right;
  }
}
.list-group-item {
  width: 20%;
  padding: 10px;
  margin-top: 10px;
  border: solid 1px #f5c319;
}
.stupreview-tit {
  display: inline-block;
  padding-left: 10px;
  margin-top: 8px;
  justify-content: flex-start;
}
.previewquestion {

}
.openquestion {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 9;
}
.opensubmithis {
  position: absolute;
  top: 0;
  right: 12%;
  z-index: 9;
}
.parentitem {
  height: 50px;
  // cursor: pointer;
}
.task-state {
  position: absolute;
  top: 0;
  right: 15%;
  z-index: 9;
  margin-top: 10px;
  color: #30a766;
}
.box-line {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.my-lesson {
  .lesson-num {
    margin-top: 25px;
    margin-bottom: 25px;
    span {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
</style>
